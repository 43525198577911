"use client";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import cn from "@/libs/cn";
import useClientContext from "@/context/ClientContext";
import { PropertyViewTypes } from "@/types/interfaces/properties";
import { hashObject } from "@/utils/data";
import useClientRouter from "@/hooks/useClientRouter";
import useThrottle from "@/hooks/useThrottle";
import FilterPriceRange, { FilterPriceRangeValue } from "./basic/FilterPriceRange";
import FilterToggle from "./basic/FilterToggle";
import FilterCheckbox from "./basic/FilterCheckbox";
import FilterColors from "./FilterColors";
import FilterBrands from "./FilterBrands";
import { useDataPublicCategoryFilters } from "../../hooks/useData";
import { useCategoryContext } from "../PageProductsListContext";

import styles from "./PanelFilters.module.scss";

export const DELAY_AUTO_APPLY_FILTERS = 100;

enum QueryParam {
  brand = "brand",
  properties = "properties",
  min_price = "min_price",
  max_price = "max_price",
  in_stock = "in_stock",
  free_delivery = "free_delivery",
  sale = "sale",
  q = "q",
}

type Query = { [Key in QueryParam]: string | undefined };

export const filterPriceRangeIsEmpty = (min?: string | number, max?: string | number) => {
  return typeof min === "undefined" || typeof max === "undefined" || !min || !max;
};

// TODO bottom code is duplicate with PanelStoreFilter getInitValues()
export const getInitValuesFromSearch = (initQuery: any) => {
  const cont: Params = {
    brands: [],
    propsChecked: {},
    price: {},
    sale: false,
    free_delivery: false,
    in_stock: false,
  };

  Object.keys(initQuery).forEach((param) => {
    if (param === QueryParam.brand && initQuery[param]) {
      cont.brands = `${initQuery[param]}`.split(",");
    } else if (param === "properties" && initQuery[param]) {
      const temp: any = {};

      const ps = `${initQuery[param]}`.split(";");

      ps.forEach((p) => {
        const t = p.split(":");
        if (t.length === 2) {
          temp[t[0]] = `${t[1]}`.split(",");
        }
      });

      cont.propsChecked = temp;
    } else if (param === QueryParam.min_price && initQuery[param]) {
      if (!cont.price) {
        cont.price = {};
      }
      const v = parseInt(initQuery[param]);
      cont.price.min = Number.isNaN(v) ? undefined : `${v}`;
    } else if (param === QueryParam.max_price && initQuery[param]) {
      if (!cont.price) {
        cont.price = {};
      }
      const v = parseInt(initQuery[param]);
      cont.price.max = Number.isNaN(v) ? undefined : `${v}`;
    } else if (param === QueryParam.sale && initQuery[param] === "1") {
      cont.sale = true;
    } else if (param === QueryParam.free_delivery && initQuery[param] === "1") {
      cont.free_delivery = true;
    } else if (param === QueryParam.in_stock && initQuery[param] === "1") {
      cont.in_stock = true;
    }
  });

  return cont;
};

export const getFilterParams = (v: Params) => {
  let filter: { [key: string]: any } = {};

  if (v.brands?.length) {
    filter.brand = v.brands.join(",");
  }

  filter.sale = v.sale ? "1" : undefined;
  filter.free_delivery = v.free_delivery ? "1" : undefined;
  filter.in_stock = v.in_stock ? "1" : undefined;

  if (v.price) {
    if (v.price.min) {
      filter.min_price = v.price.min;
    }
    if (v.price.max) {
      filter.max_price = v.price.max;
    }
  }

  let filterProperties = "";
  const pKeys = Object.keys(v.propsChecked || {});
  if (v.propsChecked && pKeys.length) {
    pKeys.forEach((key, idx) => {
      if (v.propsChecked?.[key].length) {
        filterProperties += `${key}:${v.propsChecked[key].join(",")}${idx < pKeys.length - 1 ? ";" : ""}`;
      }
    });
  }

  if (filterProperties) {
    filter.properties = filterProperties;
  }

  return filter;
};

interface PanelCategoryFiltersProps {
  categoryId?: string | number;
  initQuery: Query;
  hideBrands: boolean;
  hideProperties: boolean;
  onlyDesktop?: boolean;
  // onChangeFilter: (params: { [param: string]: string }) => void; // Example [str]: param1=value1&param2=value2...
  // loading?: boolean;
}

export interface Params {
  brands?: string[];
  propsChecked?: { [propertyId: string]: any[] };
  price?: FilterPriceRangeValue;
  sale?: boolean;
  free_delivery?: boolean;
  in_stock?: boolean;
}

const PanelCategoryFilters = (props: PanelCategoryFiltersProps) => {
  const { categoryId, initQuery = {} as Query, hideBrands, hideProperties, onlyDesktop } = props;
  const { translate, isMobileSize } = useClientContext();
  const { onChangeFilter } = useCategoryContext();
  const router = useClientRouter();

  const [ready, setReady] = useState(false);
  const [brands, setBrands] = useState<string[]>([]);
  const [propsChecked, setPropsChecked] = useState<{ [propertyId: string]: any[] }>({});
  const [draftPrice, setDraftPrice] = useState<FilterPriceRangeValue>({});
  const [price, setPrice] = useState<FilterPriceRangeValue>({});
  const [sale, setSale] = useState(false);
  const [free_delivery, setFreeDelivery] = useState(false);
  const [in_stock, setInStock] = useState(false);
  // console.log("11111111", propsChecked, initQuery, getFilterParams({propsChecked}));
  const search = initQuery[QueryParam.q];
  const { data: categoryFiltersListOld, isLoading } = useDataPublicCategoryFilters({
    categoryId,
    brand: brands.join(","),
    properties: getFilterParams({ propsChecked }).properties,
    searchStr: search,
    sale: sale ? "1" : undefined,
    min_price: price.min,
    max_price: price.max,
  });
  const [categoryFiltersList, setCategoryFiltersList] = useState(categoryFiltersListOld);
  const keyOldData = useMemo(() => hashObject(categoryFiltersListOld), [categoryFiltersListOld]);
  useEffect(() => {
    if (!isLoading) {
      setCategoryFiltersList(categoryFiltersListOld);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, keyOldData]);

  useEffect(() => {
    setReady(false);
  }, [router.search]);

  useEffect(() => {
    if (!ready) {
      const init = getInitValuesFromSearch(initQuery);

      setBrands(init.brands || []);
      setPropsChecked(init.propsChecked || {});
      setDraftPrice(init.price || {});
      setPrice(init.price || {});
      setSale(init.sale || false);
      setFreeDelivery(init.free_delivery || false);
      setInStock(init.in_stock || false);
      setReady(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ready]);

  const onChangeProp = useCallback(
    (propertyId: any, ch: any[]) => setPropsChecked({ ...propsChecked, [propertyId]: ch }),
    [propsChecked]
  );

  const throttle = useThrottle(DELAY_AUTO_APPLY_FILTERS);

  useEffect(() => {
    const filter = getFilterParams({ brands, propsChecked, price, sale, free_delivery, in_stock });

    const hashInit = hashObject(initQuery, [undefined]);
    const hashNew = hashObject(filter, [undefined]);

    if (!ready || hashNew === hashInit) {
      return;
    }

    throttle(() => onChangeFilter(filter), filter);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    ready,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    hashObject({ brands, propsChecked, price, sale, free_delivery, in_stock }),
  ]);

  const onApply = useCallback(() => {
    const filter = getFilterParams({ brands, propsChecked, sale, free_delivery, in_stock });
    onChangeFilter(filter);
  }, [brands, free_delivery, onChangeFilter, propsChecked, sale, in_stock]);

  const onApplyPrice = useCallback(() => {
    setPrice(draftPrice);
    const filter = getFilterParams({
      brands,
      propsChecked,
      sale,
      free_delivery,
      in_stock,
      price: { ...draftPrice },
    });
    onChangeFilter(filter);
  }, [brands, draftPrice, free_delivery, onChangeFilter, propsChecked, sale, in_stock]);

  const minPrice = useMemo(() => {
    if (!categoryFiltersList.min_price) {
      return undefined;
    }
    const v = parseInt(`${categoryFiltersList.min_price}`);
    return !Number.isNaN(v) ? `${v}` : undefined;
  }, [categoryFiltersList.min_price]);

  const maxPrice = useMemo(() => {
    if (!categoryFiltersList.max_price) {
      return undefined;
    }
    const v = Number(`${categoryFiltersList.max_price}`);
    return !Number.isNaN(v) ? `${Math.ceil(v)}` : undefined;
  }, [categoryFiltersList.max_price]);

  if (isMobileSize && onlyDesktop) {
    return null;
  }

  return (
    <div className={cn(styles.PanelCategoryFilters, isLoading && styles.loading)}>
      {!filterPriceRangeIsEmpty(minPrice, maxPrice) && (
        <>
          <div className={cn(styles.div)} />
          <FilterPriceRange
            label={translate("Price")}
            value={draftPrice}
            min={minPrice}
            max={maxPrice}
            onChange={setDraftPrice}
            onApply={onApplyPrice}
          />
        </>
      )}
      <div className={cn(styles.div, styles.div3)} />
      <FilterToggle label={translate("Sale")} value={sale} onChange={setSale} />
      {!hideBrands && !!categoryFiltersList.brands.length && (
        <>
          <div className={styles.div} />
          <FilterBrands checked={brands} onChange={setBrands} list={categoryFiltersList.brands} />
        </>
      )}
      {!hideProperties && !!categoryFiltersList.properties.length && (
        <>
          {categoryFiltersList.properties.map((property) => {
            const data = property.property_values.map((i) => ({
              value: i.id,
              label: i.title,
              measurable: i.measurable_value,
              quantity: i.doc_count,
            }));

            const quantity = property.property_values.reduce((prev, current) => prev + current.doc_count, 0);

            if (property.view_type?.name === PropertyViewTypes.COLOR) {
              return (
                <Fragment key={property.id}>
                  <div className={styles.div} />
                  <FilterColors
                    key={property.id}
                    data={data}
                    checked={propsChecked[property.id]}
                    onChange={(ch) => onChangeProp(property.id, ch)}
                  />
                </Fragment>
              );
            }

            return (
              <Fragment key={property.id}>
                <div className={styles.div} />
                <FilterCheckbox
                  label={property.name}
                  data={data}
                  checked={propsChecked[property.id]}
                  quantity={quantity}
                  onChange={(ch) => onChangeProp(property.id, ch)}
                />
              </Fragment>
            );
          })}
        </>
      )}

      {/* <div className={cn(styles.div, styles.div2)} /> */}
      {/* <FilterToggle label={translate("In stock")} value={in_stock} onChange={setInStock} /> */}

      {/* <div className={cn(styles.div, styles.div3)} /> */}
      {/* <FilterToggle label={translate("Free delivery")} value={free_delivery} onChange={setFreeDelivery} /> */}
      {/* <Button className={styles.buttonApply} color="primary" onClick={onApply} loading={loading}>
        {translate("Apply filters")}
      </Button> */}
    </div>
  );
};

export default PanelCategoryFilters;
