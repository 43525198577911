import { IApiSellerProfile } from "./account";
import { IApiCustomerAddress } from "./addresses";
import { IApiDeliveryProvider, IApiDeliveryProviderName, IApiFile } from "./primitives";
import { IApiTradeNumber } from "./products";
import {OrderPaymentTypes} from '@/types/interfaces/finances';

export enum IApiOrderStatus {
  PENDING = "pending",
  PAYMENT_FAILED = "payment_failed",
  APPROVED = "approved",
  SHIPPING = "shipping",
  SHIPPING_ON_THE_WAY = "shipping_on_the_way",
  ARRIVED = "arrived",
  RECEIVED = "received",

  // CANCEL_INITIATED = "cancel_initiated",
  CANCEL_INITIATED_BY_SELLER = "cancel_initiated_by_seller",
  CANCEL_INITIATED_BY_CUSTOMER = "cancel_initiated_by_customer",
  CANCELED_BY_SELLER = "canceled_by_seller",
  CANCELED_BY_CUSTOMER = "canceled_by_customer",
  CANCELED_BY_MARKET = "canceled_by_market",

  RETURN_INITIATED = "return_initiated",
  RETURN_VERIFIED_BY_SELLER = "return_verified_by_seller",
  RETURN_DECLINED_BY_SELLER = "return_declined_by_seller",
  RETURN_ARRIVED = "return_arrived",
  REFUND = "refund",
}

export const sellerOrderReadyStatuses = [
  IApiOrderStatus.APPROVED,
];

export const sellerOrderCancelInitiatedStatuses = [
  IApiOrderStatus.CANCEL_INITIATED_BY_SELLER,
  IApiOrderStatus.CANCEL_INITIATED_BY_CUSTOMER,
];

export const sellerOrderCancelStatuses = [
  IApiOrderStatus.CANCELED_BY_MARKET,
  IApiOrderStatus.CANCELED_BY_SELLER,
  IApiOrderStatus.CANCELED_BY_CUSTOMER,
];

export const customerOrderShippingStatuses = [
  IApiOrderStatus.SHIPPING,
  IApiOrderStatus.ARRIVED,
];

export const customerOrderCompletedStatuses = [
  IApiOrderStatus.SHIPPING,
  IApiOrderStatus.ARRIVED,
  IApiOrderStatus.APPROVED,
];

export const customerOrderFailedStatuses = [
  IApiOrderStatus.PAYMENT_FAILED,
  IApiOrderStatus.RETURN_DECLINED_BY_SELLER,
];

export const customerOrderCancelInitiatedStatuses = [
  IApiOrderStatus.CANCEL_INITIATED_BY_SELLER,
  IApiOrderStatus.CANCEL_INITIATED_BY_CUSTOMER,
];

export const customerOrderCancelStatuses = [
  IApiOrderStatus.CANCELED_BY_CUSTOMER,
  IApiOrderStatus.CANCELED_BY_SELLER,
  IApiOrderStatus.CANCELED_BY_MARKET,
];

export enum OrderCancelReason {
  merchant = "merchant",
  buyer = "buyer",
  market = "market",
  initiated = "initiated",
}

export interface IApiOrdrersStatus {
  name: IApiOrderStatus;
  description: string;
}

export interface IApiOrderPaymentMethod {
  id: number;
  name: string;
}

export type IApiOrdrersStatuses = IApiOrdrersStatus[];

export interface IApiSellerOrderWaybill {
  id: number;
  provider: IApiDeliveryProvider;
  created: string;
  modified: string;
  number: string;
  status: IApiOrderStatus;
  order: number;
}

export interface IApiCustomerOrderRoadmap {
  id: number;
  order: {
    id: number;
    order_id: string;
    status: string;
    store: number;
    cancel_reason: IApiOrderCancelReason | null;
  };
  created: string;
  modified: string;
  status: IApiOrderStatus;
}

export interface IApiSellerOrderRoadmap {
  id: number;
  order: {
    id: number;
    order_id: string;
    status: string;
    store: number;
  };
  created: string;
  modified: string;
  status: IApiOrderStatus;
}

export type IApiSellerOrderRoadmapList = IApiSellerOrderRoadmap[];

export type IApiDeliveryShippngMethod =
  | {
      id: number;
      provider: {
        id: number;
        name: IApiDeliveryProviderName;
        description: string;
      };
      address: null;
      created: string; // "2023-09-27T09:16:14.332414Z"
      modified: string; // "2023-09-27T09:16:14.332556Z";
      name: string; // "Shipping method 1695806857706";
      is_address_delivery: false;
      branch_data?: {
        region_present: string | null;
        district_present: string | null;
        city_present: string | null;
        branch_present: string;
        last_name: string;
        first_name: string;
        phone: string;
      };
      is_active: true;
    }
  | {
      id: number;
      provider: {
        id: number;
        name: IApiDeliveryProviderName;
        description: string;
      };
      address: IApiCustomerAddress;
      created: string; // "2023-09-27T09:16:14.332414Z"
      modified: string; // "2023-09-27T09:16:14.332556Z";
      name: string; // "Shipping method 1695806857706";
      is_address_delivery: true;
      branch_data: null;
      is_active: true;
    };
export interface CustomerOrderItem {
  id: number;
  name: string;
  main_pic?: IApiFile;
  is_variant: boolean;
  is_feedback_exists: boolean;
  sku: string | null;
  slug: string;
  product_code: string;
  warranty_term: number;
  weight: string | null;
  quantity: number;
  arrival: null;
  checkout_price: string;
  shipping_fee: string;
  variant_properties: {
    property_name: string;
    property_value_title: string;
  }[] | null;
}

export interface IApiOrderItemFeedback {
  created: string;
  rate: string;
  comment: string;
  images: {
    id: number;
    image: string;
    is_active: boolean;
  }[];
  is_liked: boolean;
  product_id: number;
  is_approved: boolean;
}

export interface IApiStoreExistedRate {
  id: number;
  product_count: number;
  created: string;
  modified: string;
  rate: string;
  comment: string | null;
  user: number;
  order: number;
}

export interface IApiCustomerOrderOfList {
  id: number;
  items: CustomerOrderItem[];
  user_id: number;
  customer_avatar: string | null;
  total_price: string;
  completed: string;
  created: string;
  modified: string;
  order_id: string;
  shipping_sum: string;
  items_sum: string;
  status: IApiOrderStatus;
  cancel_reason: {
    id: number;
    name: string;
    initiator: string;
    ordering: number;
    is_active: boolean;
  } | null;
  delivered: string | null;
  store: number;
  checkout_order: number;
  shipping_method: IApiDeliveryShippngMethod;
  waybill: {
    number: string;
    provider: string;
  } | null;
  customer: {
    id: number;
    email: string | null;
    first_name: string | null;
    last_name: string | null;
    phone: string | null;
  };
  payment_method: string | null;
  payment_type: keyof typeof OrderPaymentTypes | null;
  company_code: string | null;
  company_name: string | null;
}

export type IApiCustomerOrdersList = IApiCustomerOrderOfList[];

export interface IApiSellerOrderItem {
  id: number;
  name: string;
  main_pic?: IApiFile;
  is_variant: boolean;
  sku: string | null;
  slug: string;
  product_code: string;
  warranty_term: number;
  weight: string | null;
  trade_number: IApiTradeNumber | null;
  quantity: number;
  arrival: null;
  checkout_price: string;
  shipping_fee: string;
}

export interface IApiSellerOrderOfList {
  /*
  Note:
    platform_commission - 5%
    platform_reward
    platform_reward = items_sum * platform_commission %
    reward
    total_price= items_sum + shipping_sum
  */
  id: number;
  items: IApiSellerOrderItem[];
  customer_avatar: string | null;
  total_price: string;
  completed: string;
  created: string;
  modified: string;
  order_id: string;
  shipping_sum: string;
  items_sum: string;
  status: IApiOrderStatus;
  delivered?: boolean;
  store: number;
  shipping_method: IApiDeliveryShippngMethod;
  checkout_order: number;
  customer?: IApiSellerProfile;
  waybill: { number: string; provider: string } | null;
  user_id: number;
  platform_commission: number;
  platform_reward: number;
  reward: number;
  cancel_reason: OrderCancelReason | null;
  payment_type: keyof typeof OrderPaymentTypes | null;
  processing_delay: number | string | null;
  company_code?: string | null;
  company_name?: string | null;
}

export type IApiSellerOrdersList = IApiSellerOrderOfList[];

export enum IApiOrderQuestionInitiator {
  CUSTOMER = "customer",
  SELLER = "seller",
}
export interface IApiOrderQuestionReason {
  id: number;
  name: string;
  initiator: IApiOrderQuestionInitiator;
  ordering: number;
  is_active: boolean;
}
export interface IApiOrderWarrantyReasons {
  id: number;
  name: string;
  initiator: IApiOrderQuestionInitiator;
  ordering: number;
  is_active: boolean;
}

export interface IApiOrderCancelReason {
  id: number;
  name: string;
  initiator: IApiOrderQuestionInitiator;
  ordering: number;
  is_active: boolean;
}
